import React from 'react';
import { Icon } from 'antd';

const Header = () => (
  <div>
    <div style={{fontSize: '1.5em', textAlign: 'center'}}>
      <Icon style={{ marginRight: '0.5em' }} type="compass" />
      Shipyard
    </div>
  </div>
);

export default Header;
